import React from 'react';

import { colors } from 'variables';

import photo10 from 'assets/images/functionalities/photo10.jpg';
import photo10x2 from 'assets/images/functionalities/photo10@2x.jpg';

import sample1 from 'assets/icons/functionalities/sample.svg'

import photo11 from 'assets/images/functionalities/photo11.png';
import photo11x2 from 'assets/images/functionalities/photo11@2x.png';

import sample2 from 'assets/icons/functionalities/sample2.svg'

import photo12 from 'assets/images/functionalities/photo12.png';
import photo12x2 from 'assets/images/functionalities/photo12@2x.png';

import sample3 from 'assets/icons/functionalities/sample3.svg'

import grafika1 from 'assets/images/functionalities/Grafika1.png';
import grafika1x2 from 'assets/images/functionalities/Grafika1@2x.png';
import grafika2 from 'assets/images/functionalities/Grafika2.png';
import grafika2x2 from 'assets/images/functionalities/Grafika2@2x.png';
import grafika3 from 'assets/images/functionalities/Grafika3.png';
import grafika3x2 from 'assets/images/functionalities/Grafika3@2x.png';
import grafika4 from 'assets/images/functionalities/Grafika4.png';
import grafika4x2 from 'assets/images/functionalities/Grafika4@2x.png';
import grafika5 from 'assets/images/functionalities/Grafika5.png';
import grafika5x2 from 'assets/images/functionalities/Grafika5@2x.png';
import grafika6 from 'assets/images/functionalities/Grafika6.png';
import grafika6x2 from 'assets/images/functionalities/Grafika6@2x.png';
import grafika7 from 'assets/images/functionalities/Grafika7.png';
import grafika7x2 from 'assets/images/functionalities/Grafika7@2x.png';
import grafika8 from 'assets/images/functionalities/Grafika8.png';
import grafika8x2 from 'assets/images/functionalities/Grafika8@2x.png';
import grafika9 from 'assets/images/functionalities/Grafika9.png';
import grafika9x2 from 'assets/images/functionalities/Grafika9@2x.png';


export default [
   {
      sectionColor: colors.lightCyanMap.get(400),
      sectionID: 'functionalities-reception',
      image: photo10,
      imageX2: photo10x2,
      reverseRow: false,
      tag: 'dla recepcji',
      sectionLinkText: 'Planowanie wizyt',
      header: ['Planowanie i', <strong>umawianie wizyt</strong>],
      description:
          'Zarządzanie poradnią lekarską, szpitalem, czy praktyką medyczną bywa utrudnione, jeśli nie ma odpowiednio wypracowanej metody działania w kwestii planowania wizyt. System Proces Control wyposażony jest w narzędzia, które w dużej mierze wpływają na poprawę jakości zarządzania tego typu obszarem.',
      features: [
         {
            icon: sample1,
            header: [
               <strong>Umawianie wizyt i zarządzanie</strong>,
               ' poradnią lekarską',
            ],
            description:
               'Jedno z narzędzi systemu stworzone jest, by wspierać pracowników w obszarze umawiania wizyt. Za pomocą formularza wstępnego, pracownik placówki wprowadza dane potencjalnego pacjenta oraz termin wizyty.',
            image: grafika1,
            imageX2: grafika1x2,
         },
         {
            icon: sample1,
            header: [
               <strong>Tworzenie formularzy przyjęć pacjentów</strong>,
               ' bez znajomości kodu',
               <strong> konkretnych pracowników</strong>,
            ],
            description:
               'Po wstępnym wprowadzeniu terminu wizyty do formularza, zostaje ona potwierdzona lub nie. Jeśli tak, pacjent zostaje przydzielony do konkretnego lekarza, a termin zapisywany jest w kalendarzu dostępnym dla wszystkich pracowników. Jest to o tyle ułatwiające, że pacjent nie musi znać konkretnych lekarzy, żeby móc zapisać się na badanie.',
            image: grafika2,
            imageX2: grafika2x2,
         },
         {
            icon: sample1,
            header: [
               <strong>Śledzenie statusu każdej wizyty</strong>,
               ' z możliwością filtrowania',
            ],
            description:
               'W kalendarzu dostępny jest podgląd każdej wizyty, dzięki czemu można zaobserwować na jakim poziomie się ona znajduje. System umożliwia również filtrowanie wizyt z różnych punktów.',
            image: grafika3,
            imageX2: grafika3x2,
         },
      ],
   },
   {
      sectionColor: colors.lightCyanMap.get(100),
      sectionID: 'functionalities-doctors',
      image: photo11,
      imageX2: photo11x2,
      reverseRow: true,
      tag: 'dla lekarzy',
      sectionLinkText: 'Przeprowadzanie wizyt',
      header: ['Przyjmowanie i ', <strong>diagnostyka pacjenta</strong>],
      description:
         'System może być wykorzystywany nie tylko w recepcji, ale również przez lekarzy. Dzięki kalendarzowi, widać jasno i klarownie wszystkie umówione wizyty. Oprócz tego, jedną z funkcjonalności jest wspomaganie lekarzy przy diagnostyce. Jest to możliwe, dzięki wykorzystaniu sztucznej inteligencji.',
      features: [
         {
            icon: sample2,
            header: [
               <strong>Umawianie wizyt i zarządzanie</strong>,
               ' poradnią lekarską',
            ],
            description:
               'Dostęp do systemu z perspektywy lekarzy pozwala im również na zarządzanie własnym czasem. Wszystkie wizyty zawarte są w jednym miejscu, dzięki czemu łatwiej rozplanować sobie pracę.',
            image: grafika4,
            imageX2: grafika4x2,
         },
         {
            icon: sample2,
            header: [
               <strong>Tworzenie formularzy przyjęć pacjentów</strong>,
               ' bez znajomości kodu',
            ],
            description:
               'W formularzach przyjęć to właśnie lekarz decyduje, czy dana wizyta będzie zaakceptowana, czy nie. Dzięki temu, informacja o zgłoszeniu pacjenta od razu trafia do rąk lekarzy.',
            image: grafika5,
            imageX2: grafika5x2
         },
         {
            icon: sample2,
            header: [
               <strong>Śledzenie statusu każdej wizyty</strong>,
               ' z możliwością filtrowania',
            ],
            description:
               'Tak samo jak pracownicy recepcji, lekarze mogą śledzić status wizyty. Wszystko jest jasne i klarowne i odbywa się w jednym miejscu.',
            image: grafika6,
            imageX2: grafika6x2
         },
      ],
   },

];
